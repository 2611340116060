import React from "react"
import { Link } from "gatsby";
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
const NotFoundPage = () => (
  <Layout>
    <SEOComponent title="404: Not found" />
    <div className="four-o-four" id="fofmain">
      <div className="fof">
            <h1>Error 404</h1><br/>
            <Link to="/" className="button is-link">Go Back</Link>
      </div>
    </div> 
  </Layout>
)

export default NotFoundPage
